import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SoortenBeugelsNavigation } from "../components/SoortenBeugelsNavigation"
import { Container, Row, Col } from "react-bootstrap"
import BuitenbeugelSVG from "../assets/svgs/buitenbeugel.svg"
import VasteApparatuurSVG from "../assets/svgs/VasteApparatuur.svg"
import UitneemApparatuurSVG from "../assets/svgs/UitneembaarAlt.svg"
import ChildrenBrushingSVG from "../assets/svgs/ChildrenBrushing.svg"
import ChildBrushingSVG from "../assets/svgs/ChildBrushing.svg"

const SoortenBeugels = () => (
  <Layout sectionTitle={"Soorten Beugels"}>
    <SEO title="Soorten beugels" />
    <Container className={"text-justify"}>
      <SoortenBeugelsNavigation />
      <div id="uitneembare-apparatuur" className="alinea-padding">
        <Row>
          <h1>Uitneembare apparatuur</h1>
        </Row>
        <Row>
          <h3>Wat is uitneembare plaatapparatuur?</h3>
        </Row>
        <Row className="blob-1">
          <Col>
            <Row>
              <p>
                Het uitneembare apparaat bestaat uit een plastic plaatje, waarin
                veertjes, schroefjes en ankertjes ingebouwd zijn. Soms zit er
                ook een beugel aan, die voor de tanden doorloopt. De veertjes in
                de schroef duwen de tanden naar een goede plaats. De ankertjes
                dienen om het apparaat op zijn plaats te houden. Sommige
                uitneembare apparaten zitten helemaal los in de mond
                (functionele apparaten) en dat hoort ook zo.
                {/*<br />*/}
              </p>
              <p>
                Wanneer moet de beugel gedragen worden? Het beugeltje moet
                altijd gedragen worden, dag en nacht, eventueel bij het eten, en
                zelfs als je ziek bent, tenzij je orthodontist je andere
                instructies gaf. Je hoeft het enkel even uit te nemen, na elke
                maaltijd, om je tanden te poetsen, en je apparaat zuiver te
                maken. Ook bij het zwemmen is het veiliger je apparaat even uit
                te doen. Anders is de kans groot dat je het verliest. Neem dan
                een klein doosje mee, om je beugel in op te bergen. Het apparaat
                wordt in de mond gebracht volgens de aangeleerde methode. Doe
                alles rustig en voorzichtig, zodat je beugel niet verbuigt. Ga
                nooit met je apparaat &apos;spelen&apos; door het met je tong
                aan en uit te doen. De kans is dan erg groot dat je het breekt.
                Mocht je beugel toch stuk gaan, neem dan onmiddellijk contact
                op, en blijf niet dagenlang zonder lopen. Probeer — als het een
                beetje lukt — je apparaat toch in de mond te houden.
              </p>
            </Row>
          </Col>
          <Col sm md={4} className="my-auto">
            <UitneemApparatuurSVG />
          </Col>
        </Row>
        <Row>
          <h3>Tanden poetsen</h3>
        </Row>
        <Row>
          <p>
            Zorg er altijd voor, dat je tanden en je beugel perfect gepoetst
            zijn, en vermijd snoep en tussendoortjes. Poets je gebit grondig, en
            schenk bijzonder aandacht aan de binnenkant van je tanden.
            Voedselresten die onder het plaatje blijven zitten, gaan er heel
            vlug voor zorgen, dat het tandvlees ontsteekt. Poetsen doe je dus
            elke keer als je iets gegeten hebt en &apos;s avonds voor het
            slapengaan. Je apparaat reinig je best met een hard borsteltje,
            bijvoorbeeld een nagelborstel en wat zeep en koud water.
          </p>
        </Row>
        <Row>
          <h3>De behandeling</h3>
        </Row>
        <Row>
          <p>
            Behandeling met een uitneembaar apparaat doet geen pijn. Het geeft
            alleen de eerste dagen wat ongemak bij het spreken en eten. HET IS
            HEEL BELANGRIJK OM JUIST DAN GOED VOL TE HOUDEN EN JE BEUGEL NIET
            UIT TE LATEN. Enkel op die manier wen je er snel aan, zodat er
            verder geen problemen meer zijn. In principe kom je elke maand op
            controle. Het apparaat wordt dan nagekeken en bijgesteld. Soms
            gebeurt het, dat ná het aanspannen van het apparaat de tanden een
            paar dagen gevoelig zijn, en zelfs een beetje los lijken te staan.
            Dat is heel normaal en de pijn verdwijnt spontaan. Het uitneembare
            apparaat werkt alleen als het op z&apos;n plaats in de mond zit.
            Wordt de plaat niet gedragen zoals werd voorgeschreven, dan kan de
            behandeling mislukken, of moet ze worden stopgezet.
          </p>
        </Row>
      </div>

      <div id="buitenbeugel" className="alinea-padding">
        <Row>
          <h1>Buitenbeugel</h1>
        </Row>
        <Row>
          <Col sm md={4} lg={4} className="my-auto">
            <BuitenbeugelSVG />
          </Col>
          <Col className="my-auto">
            <Row>
              <h3>Wat is een buitenbeugel?</h3>
            </Row>
            <Row>
              <p>
                De buitenbeugel of face-bow bestaat uit een stevige metalen
                boog, die in de mond in buisjes wordt geschoven. De buisjes
                zitten vast op de bandjes, die op kiezen achter in je mond
                worden vastgezet. De boog komt naar buiten bij de mondhoeken en
                loopt langs de wangen buitenom naar achter. Hij wordt
                aangespannen met een band om de nek, of over het hoofd, of beide
                (de headgear). De buitenbeugel wordt vaak samen met andere
                apparaten toegepast.
                <br />
                Het dragen van een buitenbeugel geeft weinig ongemak, ook niet
                bij het slapen. De eerste nachten is het wel even wennen.
                &apos;s Morgens kunnen de kiezen wat gevoelig zijn, en zelfs een
                beetje losstaan. Dit is een normale reactie, en kan geen kwaad.
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <h3>Waartoe dient de buitenbeugel?</h3>
        </Row>
        <Row>
          <p>
            In de meeste gevallen zal de buitenbeugel helpen, om de boven- en de
            onderkaak juist op elkaar aan te passen. Soms wordt hij ook gebruikt
            om meer plaats in je mond te maken, door de kiezen naar achter te
            schuiven. En heel af en toe wordt hij ook gebruikt om tanden meer
            naar voor te brengen.
          </p>
        </Row>
        <Row>
          <h3>Wanneer wordt de buitenbeugel gedragen?</h3>
        </Row>
        <Row>
          <p>
            De ervaring leert dat de buitenbeugel minstens 14 uur per etmaal
            gedragen moet worden om resultaat te bekomen. Het is dus meestal
            niet nodig de buitenbeugel altijd te dragen, behalve als je
            orthodontist dit nodig vindt. Je moet er wél op letten, het vereiste
            aantal uren te halen, ook tijdens verlofperiodes of weekends.
            <br />
            Bij het eten doe je je buitenbeugel uit, en je doet hem terug in
            nadat je tanden gepoetst zijn.
            <br />
            Onvoldoende dragen doet een behandeling soms mislukken, of maakt ze
            onnodig moeilijk en langdurig.
          </p>
        </Row>
        <Row>
          <h3>Tanden poetsen</h3>
        </Row>
        <Row>
          <p>
            De beugel beschadigt je tanden niet, maar het is wel moeilijker om
            tanden en kiezen goed schoon te houden. Poets dus elke keer als je
            iets gegeten hebt, en voor het slapen gaan. Tanden poetsen gebeurt
            zoals je aangeleerd werd. Poets extra goed rond de slotjes achteraan
            op je kiezen, en let erop dat er geen voedselresten blijven zitten,
            anders kan je tandvlees zwellen en gaan bloeden. Soms zijn er achter
            de tanden waarop de bandjes zitten nog kiezen aanwezig, of ze breken
            door tijdens de behandeling. Ook deze tanden mogen niet vergeten
            worden. De buitenbeugel moet regelmatig door je orthodontist
            bijgesteld worden. Breng hem daarom bij ieder controlebezoek mee.
            Als de beugel stuk gaat, of een bandje komt los te zitten, probeer
            dan zo snel mogelijk een tussentijdse afspraak te krijgen, en wacht
            niet tot bij je volgende controle. Als er andere problemen zijn, die
            het goed dragen van je beugel belemmeren, contacteer dan even, of
            vertel het bij je eerstvolgende bezoek.
          </p>
        </Row>
      </div>
      <div id="vaste-apparatuur" className="alinea-padding">
        <Row>
          <h1>Vaste apparatuur</h1>
        </Row>
        <Row>
          <Col className="my-auto">
            <Row>
              <h3>Wat is vaste apparatuur?</h3>
            </Row>
            <Row>
              <p>
                Het vaste apparaat is, zoals de naam verraadt, vast in de mond
                bevestigd en kan dus niet door jezelf uitgenomen worden. Het
                bestaat uit veerkrachtige draden (bogen) die vastgebonden worden
                aan metalen of porseleinen slotjes (brackets).
              </p>
            </Row>
            <Row>
              <h3>Het apparaat</h3>
            </Row>
            <Row>
              <p>
                De slotjes (tubes) kunnen vastzitten op metalen bandjes die rond
                de kiezen worden gecementeerd. Op de voorste tanden worden de
                slotjes of brackets doorgaans rechtstreeks op de tanden
                gekleefd. Het vastkleven doet geen pijn, maar is nogal
                tijdrovend.
              </p>
            </Row>
          </Col>
          <Col sm md={4} lg={4} className="my-auto">
            <VasteApparatuurSVG />
          </Col>
        </Row>

        <Row>
          <h3>De behandeling</h3>
        </Row>
        <Row>
          <p>
            Zodra de beugel is geplaatst, zet de draad je tanden in beweging.
            Het is dan ook normaal dat die tanden een beetje los komen te staan
            en wat gevoeliger worden. Dit spanningsgevoel kan tot drie dagen
            aanhouden en neemt daarna af. Indien nodig kan men een pijnstiller
            innemen om het onaangename gevoel te verlichten. Gelijkaardige
            verschijnselen zullen zich ook voordoen wanneer je beugel later weer
            wordt aangespannen. Het aanspannen gebeurt meestal eens per maand en
            vaak worden dan ook de bogen vervangen. Er komen stevigere draden in
            de plaats. De eerste dagen na het plaatsen kan het apparaat
            irritaties veroorzaken op tong en wangen. Ook deze problemen
            verdwijnen vrij snel, eens de mond zich aan de schurende
            aanwezigheid en de dikte van de blokjes heeft aangepast. Om de last
            te verlichten kan men een beetje was op het oorzakelijke droge
            blokje duwen, zodat het wondje sneller kan genezen. Als de irritatie
            veroorzaakt wordt door een beugelonderdeel dat werkelijk prikt, zal
            de was wel tijdelijk een verlichting bieden, maar spontane
            aanpassing zal echter uitblijven. Dan neem je best contact op met je
            orthodontist om dit euvel aan te pakken. Echte aften (witte
            pijnlijke vlekjes met een rode rand) genezen pas na een tiental
            dagen. Om de pijn te verzachten kan je spoelen met lauw zout water,
            je kan het aanstippen met een zilvernitraatstiftje (verkrijgbaar bij
            de apotheek, maar opgepast!) of je kan er Pyralvex® op stippen.
          </p>
        </Row>
        <Row>
          <Col sm md={4} lg={4} className="my-auto">
            <ChildBrushingSVG />
          </Col>
          <Col className="my-auto">
            <Row>
              <h3>Wat met het eten?</h3>
            </Row>
            <Row>
              <p>
                De dunne bogen en veertjes kunnen verbuigen of breken en de
                blokjes kunnen worden afgebroken. Daarom moet je hard en taai of
                kleverig voedsel vermijden. Appels, stokbrood, wortels,
                chocoladerepen… kun je best in stukjes breken/snijden, vooraleer
                je ze eet. Zeker geen hard voedsel afbijten! Kleverige voeding
                (caramel en kauwgum) kunnen de beugeldelen verbuigen waardoor
                het apparaat verkeerd kan inwerken op je tanden.
              </p>
            </Row>
          </Col>
        </Row>

        <Row>
          <h3>Als je ook elastieken moet dragen</h3>
        </Row>
        <Row>
          <p>
            Als onderdeel van een orthodontische behandeling worden vaak
            elastieken gebruikt. Ze worden gedragen samen met vaste, soms ook
            met losse apparatuur. De elastiekjes leveren de kracht die nodig is
            om de tanden, of groepen van tanden te verplaatsen. Het is daarom
            erg belangrijk dat ze worden gedragen zoals door de orthodontist
            voorgeschreven. De elastiekjes moeten regelmatig vernieuwd worden.
            Ze verweken in de mond of gaan stuk. Je krijgt er dus een voorraad
            mee naar huis. Als je elastiekjes bijna op zijn, vraag je nieuwe.
            Het is handig de kleur of de code die op de zakjes staat te
            onthouden. Je mag de elastiekjes uitdoen om te eten en om de tanden
            te poetsen. Neem een zakje elastiekjes mee in je schooltas of in je
            zak, zodat, als er eentje knapt, je meteen een nieuwe kan
            aanbrengen.
          </p>
        </Row>
        <Row>
          <h3>Tanden poetsen</h3>
        </Row>
        <Row>
          <Col className="my-auto">
            <Row>
              <p>
                Bedenk dat elke keer dat je eet, door de steeds aanwezige
                mondbacteriën een zuur wordt aangemaakt. Zij doen dit door
                koolhydraten (suikers, zetmeel) te vergisten. Dit zuur tast het
                tandglazuur aan, lost het op en veroorzaakt op die manier
                lelijke witte vlekken die niet meer verdwijnen. Bij een
                aanhoudende zuuraanval worden deze vlekken putjes en eens de
                putjes een diepte bereiken door het glazuur, ontstaat er een
                gaatje. Het zuur wordt gevormd in de tandplak. Dit is het witte
                laagje dat zich op de tanden vormt op plaatsen waar niet goed
                gepoetst wordt. Onesthetische witte vlekken op het zichtbare
                voorvlak van de tanden na het dragen van vaste apparatuur, doen
                zich nooit voor waar de blokjes hebben gezeten, maar wel rondom
                deze blokjes. Deze witte vlekken zijn dus geen gevolg van de
                apparatuur, maar wel van een ernstige verwaarlozing van de
                mondhygiëne door de patiënt. De vaste beugel beschadigt je
                tanden niet, zolang alles maar goed wordt schoongehouden. Tanden
                poetsen met een vast apparaat vraagt wat meer aandacht. Achter
                de draadjes en slotjes blijft makkelijk plak (vuil) zitten. Als
                die niet op tijd en grondig wordt weggepoetst, gaat je tandvlees
                ontsteken en bloeden. Er ontstaan heel vlug gaatjes en lelijke
                verkleuringen op je tanden. Soms is het dan noodzakelijk je
                behandeling vroegtijdig te onderbreken, waardoor alle moeite
                voor niets is geweest. Na elke maaltijd is het absoluut nodig om
                heel grondig te poetsen en tussendoortjes kan je dus best
                vermijden.
              </p>
            </Row>
          </Col>
          <Col sm md={4} lg={4} className="my-auto">
            <ChildrenBrushingSVG />
          </Col>
        </Row>
        <Row>
          <p>
            Poets eerst je tanden ter hoogte van het tandvlees, waar de meeste
            plak blijft zitten. Doe dit met kleine cirkelvormige
            schrobbewegingen en poets lang genoeg. Ga systematisch te werk en
            sla geen tanden over. Poets alle vlakken, niet alleen daar waar de
            beugel zit, maar ook de kauwvlakken en de binnenkant van je tanden.
            Voor moeilijk bereikbare plaatsen kan je een kleine interdentaal of
            een &apos;single-tufted&apos; borstel gebruiken. Kijk steeds in de
            spiegel of alles goed schoon is en let nog eens extra op de
            achterste blokjes. De metaaldelen moeten altijd glimmend gehouden
            worden. Eventueel kan je een poetstest doen met behulp van
            tandplakverklikkers. Deze kleurtabletjes maken de witte tandplak
            (het vuil) beter zichtbaar. &apos;s Avonds voor het slapengaan, is
            het aan te raden om na het poetsen je mond te spoelen met ongeveer
            10 ml fluoridespoeling. Dit versterkt de tanden en voorkomt het
            ontstaan van tandbederf.
          </p>
        </Row>
      </div>
      <div id="botankers" className="alinea-padding">
        <Row>
          <h1>Recente botankers</h1>
        </Row>
        <Row>
          <p>
            Bij de uitvoering van een orthodontische behandeling moet te allen
            tijde rekening worden gehouden met het optreden van ongewenste
            verplaatsingen van gebitselementen ten gevolge van reactiekrachten
            en -momenten. Deze ongewenste verplaatsingen kunnen slechts worden
            voorkomen door verankering van de gebitselementen die
            reactiekrachten ondergaan.
            <br />
            Om verankering tot stand te brengen wordt een groot aantal
            verschillende behandelstrategiën en orthodontische apparaten
            toegepast. Naast het gebruik van blokverankering met groepen
            gebitselementen kan extra verankering worden verkregen door middel
            van bijkomende buiten- of binnenbeugels. Tegenwoordig kiezen we vaak
            voor de revolutionaire botverankering.
            <br />
            Een botanker is een metalen schroefje (of plaatje) dat gefixeerd
            wordt in (aan) het kaakbot. Zowel het plaatsen als het verwijderen
            van deze botankers kan onder plaatselijke verdoving. Het zijn kleine
            en weinig tijdrovende behandelingen.
          </p>
        </Row>
      </div>
    </Container>
  </Layout>
)

export default SoortenBeugels
